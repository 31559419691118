import to from 'await-to-js';
import {
    urlMap,
} from './common';
import {
    get,
    fixJSONKeys,
} from './utils';

export async function getList(params, useNodejsApi = false) {
    // 参数useNodejsApi的用途是，解决目前'cms/content/list@v2'接口未能实现按用户身份查询自己可读的文章
    const urlKey = useNodejsApi ? 'cms/content/nodejs-get' : 'cms/content/list@v2';
    const [_error, data]  = await to(get(urlMap[urlKey], params));
    if (!_error && data && data.list) {
        data.list = data.list.map(fixJSONKeys);
    }
    return data;
}