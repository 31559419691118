import sidebar from '../custom-sidebar';
import navItem2 from '../nav-item2';
import { env } from '../common';
import { getList } from '../contents';

const treeMap = {
    qa: [
        {
            id: '1',
            name: '外部行业报告',
            pid: null,
            selectable: false,
        },
        {
            termId: '793520667650872320',
            name: '能源/电力',
            pid: '1',
        },
        {
            termId: '793520787016613888',
            name: '风电',
            pid: '1',
        },
        {
            termId: '793521225816221696',
            name: '光伏',
            pid: '1',
        },
        {
            id: '2',
            name: '内部研究成果',
            pid: null,
            selectable: false,
        },
        {
            termId: '790989793948570624',
            name: '集团品牌部',
            pid: '2',
        },
        {
            termId: '793521058509677568',
            name: '绿色金融',
            pid: '2',
        },
    ],
};

const tempTree = treeMap[env] || [
    {
        id: '1',
        name: '外部行业报告',
        pid: null,
        selectable: false,
    },
    {
        termId: '930113512179721216',
        name: '能源/电力',
        pid: '1',
    },
    {
        termId: '930114331448928256',
        name: '风电',
        pid: '1',
    },
    {
        termId: '930114652959088640',
        name: '光伏',
        pid: '1',
    },
    {
        termId: '930114807867257856',
        name: '氢能',
        pid: '1',
    },
    {
        termId: '930114917514783744',
        name: '储能',
        pid: '1',
    },
    {
        termId: '930115014902380544',
        name: '零碳相关',
        pid: '1',
    },
    {
        id: '2',
        name: '内部研究成果',
        pid: null,
        selectable: false,
    },
    {
        termId: '930114125730876416',
        name: '集团品牌部',
        pid: '2',
    },
    {
        termId: '930114204864862208',
        name: '绿色金融',
        pid: '2',
    },
];

export const tree = tempTree.map(item => {
    return  {
        ...item,
        id: item.id || item.termId || item.contentId,
    };
});

export default {
    mixins: [sidebar],
    components: {
        navItem2,
    },
    props: ['countMap'],
    name: 'industry-research-sidebar',
    data() {
        return {
            tree,
            routeName: 'industry-research-list',
        };
    },
    async created() {
        // 仅当“内部研究成果”有内容时再显示相应的左侧导航项
        const context = this;
        const contents = context.tree.filter(item => item.pid === '2');
        const countMap = {};
        for(const item of contents) {
            const params = {
                termId: item.termId,
                markA: 1,
            };
            const data = await getList(params, true);
            if (data?.list.length > 0) {
                countMap[item.id] = data.count;
            }
        }
        context.$emit('update:countMap', countMap);
    },
};